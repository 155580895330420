import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = [ 'tagField' ];
  connect() {
    if (this.hasTagFieldTarget) {
      this.enableTS();
    }
  }

  disconnect() {
  }

  enableTS() {
    new TomSelect(this.tagFieldTarget, {create: true});
  }
}