import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['overlay'];

  connect() {
    const icon = this.overlayTarget.querySelector('img');
    icon.addEventListener('animationend', this.handleAnimationEnd.bind(this));
  }

  handleAnimationEnd() {
    this.removeOverlay();
  }

  removeOverlay() {
    this.overlayTarget.remove();
  }
}