import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "kiosksModal" ]

  connect() {
    this.modal = Modal.getOrCreateInstance(this.kiosksModalTarget);
  }

  close(event){
    event.preventDefault()
    this.modal.hide()
    window.location = event.srcElement.href
  }

  async get_kiosks(event) {
    event.preventDefault()

    const response = await get(event.srcElement.href, {
      responseKind: "turbo-stream"
    })

    if(response.ok) {
      this.modal.show()
    }
  }
}

