import { Controller } from "@hotwired/stimulus"
import { tsParticles } from "@tsparticles/engine";
import { loadConfettiPreset } from "@tsparticles/preset-confetti";

export default class extends Controller {
  static targets = [ "" ]

  sparkled = null

  connect() {
    this.sparkled = false;
    loadConfettiPreset(tsParticles);  // Load the confetti preset
  }

  async addSparkles() {
    if (this.sparkled) {
      return;
    }
     
    tsParticles.load({
      id: "tsparticles",
      options: {
        "fullScreen": {
          "zIndex": 9999
        },
        "emitters": [
          {
            "life": {                      
              duration: 1,
              count: 1,
            },
            "position": {
              "x": 0,
              "y": 50
            },
            "rate": {
              "quantity": 10,
              "delay": 0.15
            },
            "particles": {
              "move": {
                "direction": "top-right",
                "outModes": {
                  "top": "none",
                  "left": "none",
                  "default": "destroy"
                }
              }
            }
          },
          {
            "life": {                      
              duration: 1,
              count: 1,
            },
            "position": {
              "x": 100,
              "y": 50
            },
            "rate": {
              "quantity": 10,
              "delay": 0.15
            },
            "particles": {
              "move": {
                "direction": "top-left",
                "outModes": {
                  "top": "none",
                  "right": "none",
                  "default": "destroy"
                }
              }
            }
          }
        ],
        "particles": {
          "color": {
            "value": [
              "#1a2abb", "#ab07a4", "#954ccf", "#8d52be"
            ]
          },
          "move": {
            "decay": 0.05,
            "direction": "top",
            "enable": true,
            "gravity": {
              "enable": true
            },
            "outModes": {
              "top": "none",
              "default": "destroy"
            },
            "speed": {
              "min": 30,
              "max": 80
            }
          },
          "number": {
            "value": 0
          },
          "opacity": {
            "value": 1
          },
          "rotate": {
            "value": {
              "min": 0,
              "max": 360
            },
            "direction": "random",
            "animation": {
              "enable": true,
              "speed": 30
            }
          },
          "tilt": {
            "direction": "random",
            "enable": true,
            "value": {
              "min": 0,
              "max": 360
            },
            "animation": {
              "enable": true,
              "speed": 30
            }
          },
          "size": {
            "value": {
              "min": 0,
              "max": 10
            },
            "animation": {
              "enable": true,
              "startValue": "min",
              "count": 1,
              "speed": 16,
              "sync": true
            }
          },
          "roll": {
            "darken": {
              "enable": true,
              "value": 25
            },
            "enable": true,
            "speed": {
              "min": 5,
              "max": 15
            }
          },
          "wobble": {
            "distance": 30,
            "enable": true,
            "speed": {
              "min": -7,
              "max": 7
            }
          },
          "shape": {
            "type": [
              "circle",
              "square"
            ],
            "options": {}
          }
        }
      }
    });
    this.sparkled = true;
  }
  
  
}
 