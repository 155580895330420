import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'

export default class extends Controller {
  static targets = [ "modal" ]

  connect() {
    this.modal = Modal.getOrCreateInstance(this.modalTarget);
  }

  item_created(event) {
    event.preventDefault()
    let success = event.detail.success

    if(success) {
      this.modal.hide()
      event.target.reset()
    }
  }

  item_updated(event){
    event.preventDefault()
    let success = event.detail.success

    if(success){
      this.modal.hide()
      event.target.reset()
    }
  }

  add_item(event){
    this.modal.show()
  }

  edit_item(event) {
    this.modal.show()
  }


  reset_form(event) {
      this.element.reset()
  }
}

