import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper/bundle'
import { post } from '@rails/request.js'



export default class extends Controller {
  static targets = [ "" ]
  static values = {
    duration: Number,
    url: String,
  }
  

  idleTime = 0
  intervalId = null
  screenSaverOn = false
  swiper = null

  initialize(){
    
  }

  connect() {
    this.startSlideshow()
  }

  disconnect() {
    this.stopSlideshow();
  }

  stopSlideshow(){
    this.screenSaverOn = false;
    this.swiper.destroy()
  }

  startSlideshow(){
    this.screenSaverOn = true
    this.randomizeSlides()  
    this.swiper = new Swiper('.banner-ads-swiper-container', {
      pagination: false,
      paginationClickable: false,
      autoplay: {
        delay: this.durationValue * 1000,
      },
      loop: true,
      allowTouchMove: false,
      speed: 1000,
      parallax: false,
      width: 1080,
      height: 600,
      centeredSlides: true,
    });
    this.swiper.on('afterInit', function (event) {
      console.log("Started")
      console.log(event.slides[event.snapIndex])
    });
    this.swiper.on('slideChangeTransitionStart', (event) => {
      var banner_ad_id = (event.slides[event.snapIndex].id).split('_').pop()
      this.postImpression(banner_ad_id)
    });
    document.getElementById("bannerAdsSwiperContainer").classList.remove("d-none")
  }  

  randomizeSlides() {
    var slides = document.querySelectorAll('.banner-ads-random-slide');
    var slideArray = Array.prototype.slice.call(slides);
    slideArray.sort(function () { return 0.5 - Math.random() });
    for (var i = 0; i < slideArray.length; i++) {
        slides[0].parentNode.appendChild(slideArray[i]);
    }
  }

  async postImpression(banner_ad_id){
    let data = JSON.stringify({
      impressions: {
        impression_attributes: [
          { trackable_id: banner_ad_id, trackable_type: 'Advertisement' }
        ]
      }
    })
 
    const response = await post(this.urlValue, { body: data, responseKind: "json" })
  }
}

