import { Controller } from "@hotwired/stimulus"
import { Swiper } from 'swiper/bundle'



export default class extends Controller {
  static targets = [ "" ]
  static values = {
    rows: Number
  }

  initialize(){
    
  }

  connect() {
    this.startSlideshow()
  }

  disconnect() {
    this.stopSlideshow();
  }

  stopSlideshow(){
    this.screenSaverOn = false;
    this.swiper.destroy()
  }

  startSlideshow(){
    this.screenSaverOn = true
    this.swiper = new Swiper('.directory-listings-swiper-container', {
      grid: {
        rows: this.rowsValue,
        fill: 'row'
      },
      slidesPerView: 2,
      spaceBetween: 10,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      on: {
        afterInit: function () {
          var swiper = this;
  

          // Temporarily disable touch events to prevent user interaction during animation
          swiper.allowTouchMove = false;

          // Partial swipe to the next slide
          swiper.slideNext(1000); // Adjust the duration as per your preference

          // Animate back to the initial position
          setTimeout(function () {
            swiper.slidePrev(400); // Adjust the duration as per your preference
            swiper.allowTouchMove = true; // Re-enable touch events
          }, 600); // Adjust the timeout duration as per your preference
        }
      }
    });
  }  
}

