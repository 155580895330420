import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper/bundle'
import { post } from '@rails/request.js'


export default class extends Controller {
  static targets = [ "" ]
  static values = {
    duration: Number,
    wait: Number,
    exit: String,
    clickToExit: Boolean,
    url: String,
  }
  

  idleTime = 0
  intervalId = null
  screenSaverOn = false
  swiper = null

  initialize(){
    
  }

  connect() {
    this.startIdle()
  }

  disconnect() {
    this.stopIdle();
  }

  startIdle(){
    if(this.intervalId){
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(() => {
      this.timerIncrement()
    }, 1000); // 1 Second

  }
  stopIdle(){
    clearInterval(this.intervalId);
    this.idleTime = 0;
    if(this.swiper){
      this.swiper.destroy()
    }
  }

  timerIncrement() {
    this.idleTime = this.idleTime + 1;
    if (this.idleTime > this.waitValue-1 && !this.screenSaverOn) { // 10 Seconds
        this.startScreenSaver();
    }
  }

  stopScreenSaver(){
    if(this.screenSaverOn){
      this.screenSaverOn = false;
      document.getElementById("swiperContainer").classList.add("d-none")
      document.getElementById("kioskFooter").classList.add("d-none")
      Turbo.visit(this.exitValue)
    }
    else if(this.clickToExitValue){
      Turbo.visit(this.exitValue)
    }
  }

  startScreenSaver(){
    this.screenSaverOn = true
    this.stopIdle()
    this.randomizeSlides()  
    this.swiper = new Swiper('.swiper-container', {
      pagination: false,
      paginationClickable: false,
      autoplay: {
        delay: this.durationValue * 1000,
      },
      loop: true,
      allowTouchMove: false,
      speed: 1000,
      parallax: false,
      width: 1080,
      height: 1920,
      centeredSlides: true,
    });
    this.swiper.on('slideChangeTransitionStart', (event) => {
      if(event.slides[event.snapIndex].id != "main-splash-page"){
        var banner_ad_id = (event.slides[event.snapIndex].id).split('_').pop()
        this.postImpression(banner_ad_id)
      }
    });
    document.getElementById("kioskFooter").classList.remove("d-none")
    document.getElementById("swiperContainer").classList.remove("d-none")
  }  

  randomizeSlides() {
    var slides = document.querySelectorAll('.random-slide');
    var slideArray = Array.prototype.slice.call(slides);
    slideArray.sort(function () { return 0.5 - Math.random() });
    for (var i = 0; i < slideArray.length; i++) {
        slides[0].parentNode.appendChild(slideArray[i]);
    }
  }

  async postImpression(banner_ad_id){
    let data = JSON.stringify({
      impressions: {
        impression_attributes: [
          { trackable_id: banner_ad_id, trackable_type: 'Advertisement' }
        ]
      }
    })
 
    const response = await post(this.urlValue, { body: data, responseKind: "json" })
  }
}

