import { Controller } from "@hotwired/stimulus"



export default class extends Controller {
  static targets = [ "" ]
  static values = {
    wait: Number,
    exit: String,
  }
  

  idleTime = 0
  intervalId = null
  screenSaverOn = false
  swiper = null

  connect() {
    this.startIdle()
  }

  disconnect() {
    this.stopIdle();
  }

  startIdle(){
    if(this.intervalId){
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(() => {
      this.timerIncrement()
    }, 1000); // 1 Second

  }
  stopIdle(){
    clearInterval(this.intervalId);
    this.idleTime = 0;
  }

  resetIdle(){
    clearInterval(this.intervalId);
    this.idleTime = 0;
    this.startIdle()
  }

  timerIncrement() {
    this.idleTime = this.idleTime + 1;
    if (this.idleTime > this.waitValue-1) { // 10 Seconds
        this.goToSplash();
    }
  }

  goToSplash(){
    Turbo.visit(this.exitValue)
  }
}

