// app/javascript/custom/companion.js
if(window.location.pathname.startsWith("/pub/kiosks/")){
  if (navigator.serviceWorker) {
    navigator.serviceWorker.register("/service-worker.js?v=18", { scope: "/" })
      .then(() => navigator.serviceWorker.ready)
      .then((registration) => {
        if ("SyncManager" in window) {
          registration.sync.register("sync-forms");
        } else {
          window.alert("This browser does not support background sync.")
        }
      });
  }
}