import { Controller } from "@hotwired/stimulus"
import lightGallery from 'lightgallery';

export default class extends Controller {
  static targets = [ "gallery"];

  connect() {
    lightGallery(this.galleryTarget, {
      selector: '.image-gallery-item',
      download: false,
      licenseKey: 'E6D17F17-6F50-429B-A2D4-DA0DA7EDAE2A',
    });
  }

  
}
