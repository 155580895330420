import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-manager"
export default class extends Controller {
  static targets = [];

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "listing-images"
  }

  remove_image(event) {
    event.preventDefault()
    if( confirm("Are you sure?") ){
      let wrapper = event.target.closest("." + this.wrapperClass)
      if (wrapper) {
        wrapper.querySelector("input[name*='_destroy']").value = 1
        wrapper.style.display = 'none'
      }
    }
   
  }
}
