import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'



export default class extends Controller {
  static targets = [ "listing" ]
  static values = {
    listingId: Number,
    impressionsUrl: String,
    clicksUrl: String,
  }

  connect() {
 
    this.postImpressions()
    this.postClicks()
  }

  async postImpressions(){
    let trackables = []
  
    if(this.listingIdValue){
      trackables.push({ trackable_id: this.listingIdValue, trackable_type: 'DirectoryListing' })
    }
    
    this.listingTargets.forEach((listing) => {
      trackables.push({ trackable_id: parseInt(listing.id.split('_').pop()), trackable_type: 'DirectoryListing' })
    })

    let data = JSON.stringify({
      impressions: {
        impression_attributes: trackables
      }
    })
 
    const response = await post(this.impressionsUrlValue, { body: data, responseKind: "json" })
  }

  async postClicks(){
    if(this.listingIdValue){
      let data = JSON.stringify({
        click: {
           directory_listing_id: this.listingIdValue
        }
      })
   
      const response = await post(this.clicksUrlValue, { body: data, responseKind: "json" })
    }
  }
}

